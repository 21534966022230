import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import GQLCodeSnippet, { GQLOpen, GQLClose, GQLLine, GQLTab, GQLName, GQLKeyword, GQLAttribute, GQLOperator, GQLComment, GQLLink } from "../../components/GQLCodeSnippet/GQLCodeSnippet";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`The Query type represents all the queryable entry points to the API.`}</p>
    <GQLCodeSnippet mdxType="GQLCodeSnippet">
      <GQLLine mdxType="GQLLine"><GQLKeyword mdxType="GQLKeyword">type</GQLKeyword> Query <GQLOpen mdxType="GQLOpen" /></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Get all regions.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">regions</GQLName>: [<GQLLink href="/ecdn-api-objects/Region" mdxType="GQLLink">Region</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator>]<GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Get all servers.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">servers</GQLName>: [<GQLLink href="/ecdn-api-objects/Server" mdxType="GQLLink">Server</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator>]<GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Get server by ID.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">serverByID</GQLName>(</GQLLine>
		<GQLLine mdxType="GQLLine"> </GQLLine>
				<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># ID of queried server.</GQLComment></GQLLine>
			<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLAttribute mdxType="GQLAttribute">id</GQLAttribute>: <GQLLink href="/ecdn-api-scalars/ID" mdxType="GQLLink">ID</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
		<GQLLine mdxType="GQLLine"> </GQLLine>
		<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" />): <GQLLink href="/ecdn-api-objects/Server" mdxType="GQLLink">Server</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Get all locations.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">locations</GQLName>: [<GQLLink href="/ecdn-api-objects/Location" mdxType="GQLLink">Location</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator>]<GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Get location by ID.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">locationByID</GQLName>(</GQLLine>
		<GQLLine mdxType="GQLLine"> </GQLLine>
				<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># ID of queried location.</GQLComment></GQLLine>
			<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLTab mdxType="GQLTab" /><GQLAttribute mdxType="GQLAttribute">id</GQLAttribute>: <GQLLink href="/ecdn-api-scalars/ID" mdxType="GQLLink">ID</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
		<GQLLine mdxType="GQLLine"> </GQLLine>
		<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" />): <GQLLink href="/ecdn-api-objects/Location" mdxType="GQLLink">Location</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLComment mdxType="GQLComment"># Get logged in user.</GQLComment></GQLLine>
	<GQLLine mdxType="GQLLine"><GQLTab mdxType="GQLTab" /><GQLName mdxType="GQLName">me</GQLName>: <GQLLink href="/ecdn-api-objects/User" mdxType="GQLLink">User</GQLLink><GQLOperator mdxType="GQLOperator">!</GQLOperator></GQLLine>
      <GQLLine mdxType="GQLLine"> </GQLLine>
      <GQLLine mdxType="GQLLine"><GQLClose mdxType="GQLClose" /></GQLLine>
    </GQLCodeSnippet>
    <h2>{`Fields`}</h2>
    <h3>{`regions: [`}<a parentName="h3" {...{
        "href": "/ecdn-api-objects/Region"
      }}>{`Region`}</a>{`!]!`}</h3>
    <p>{`Get all regions.`}</p>
    <h3>{`servers: [`}<a parentName="h3" {...{
        "href": "/ecdn-api-objects/Server"
      }}>{`Server`}</a>{`!]!`}</h3>
    <p>{`Get all servers.`}</p>
    <h3>{`serverByID: `}<a parentName="h3" {...{
        "href": "/ecdn-api-objects/Server"
      }}>{`Server`}</a>{`!`}</h3>
    <p>{`Get server by ID.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`ARGUMENT`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`NULLABLE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`LIST`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/ecdn-api-scalars/ID"
            }}>{`ID`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ID of queried server.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`locations: [`}<a parentName="h3" {...{
        "href": "/ecdn-api-objects/Location"
      }}>{`Location`}</a>{`!]!`}</h3>
    <p>{`Get all locations.`}</p>
    <h3>{`locationByID: `}<a parentName="h3" {...{
        "href": "/ecdn-api-objects/Location"
      }}>{`Location`}</a>{`!`}</h3>
    <p>{`Get location by ID.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`ARGUMENT`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`NULLABLE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`LIST`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/ecdn-api-scalars/ID"
            }}>{`ID`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YES`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ID of queried location.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`me: `}<a parentName="h3" {...{
        "href": "/ecdn-api-objects/User"
      }}>{`User`}</a>{`!`}</h3>
    <p>{`Get logged in user.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      